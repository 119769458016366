import React from "react"

import Layout from "src/layout"
import Menu from "src/components/Menu"
import Column from "src/layout/column"
import { EMAIL_ADDRESS, MAPS_URL } from "src/helpers/constants"

const LegalPage: GatsbyPage = () => {
  return (
    <Layout title="Mentions légales et Conditions Générales de Vente" noindex>
      <Menu />
      <Column>
        <h1>Mentions légales</h1>
        <p>
          Mentions légales réglementées par la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
          aux libertés et la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.
        </p>

        <h2>Confidentialité des données personnelles</h2>
        <p>
          Vos données personnelles (nom, prénom, adresse, code postal, ville, courriel, téléphone) sont collectées dans
          le strict respect de la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.
        </p>
        <p>
          Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés
          modifiée par la nouvelle loi Informatique et Libertés du 6 août 2004, vous disposez d’un droit d’accès, de
          modifications, de rectifications et de suppression des données qui vous concernent. Pour cela il vous suffit
          de nous contacter par courrier, par courriel ou par téléphone.
        </p>

        <h2>Nos coordonnées</h2>
        <p>
          l’attrape miel
          <br />
          <a href={MAPS_URL}>
            Le bourg
            <br />
            23460 Saint-Martin-Château
          </a>
        </p>
        <p>
          Courriel : <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
        </p>
        <p>SIRET : 841 236 763 00014</p>

        <h2>Éditeurs du site</h2>
        <p>
          Développement : <a href="https://antoine.rousseau.im/">Antoine Rousseau</a>
        </p>
        <p>
          Dessin : <a href="https://www.camilleulrich.fr/">Camille Ulrich</a>
        </p>
        <p>
          Hébergement : <a href="https://www.netlify.com">Netlify</a> et{" "}
          <a href="https://www.contentful.com">Contentful</a>
        </p>
        <p>
          Paiement : <a href="https://stripe.com/fr">Stripe</a>
        </p>

        <h2>Conditions générales de vente</h2>
        <p>
          Les présentes conditions de vente sont conclues entre l’attrape miel - Cédric Perrot, apiculteur récoltant,
          SIRET 841 236 763 00014, dont l’Activité Principale Exercée (APE) est{" "}
          <i>0149Z Elevage d’autres animaux - Apiculture</i>, soumis au régime d’imposition des bénéfices agricoles
          Forfait BA et au régime d’imposition de la TVA <i>Remboursement Forfaitaire Agricole</i> et par toute personne
          physique ou morale souhaitant procéder à un achat via ce site Internet, dénommée ci-après « l’acheteur·euse ».
        </p>

        <h3>Objet</h3>
        <p>
          Les présentes conditions de vente visent à définir les relations contractuelles entre l’attrape miel et
          l’acheteur·euse et les conditions applicables à tout achat effectué par le biais du site marchand de l’attrape
          miel, que l’acheteur·euse soit professionnel·le ou consommateur·trice
        </p>
        <p>
          L’acquisition d’un bien ou d’un service à travers le présent site implique une acceptation sans réserve par
          l’acheteur·euse des présentes conditions de vente. Ces conditions de vente prévaudront sur toutes autres
          conditions générales ou particulières non expressément agréées par l’attrape miel.
        </p>
        <p>
          L’attrape miel se réserve le droit de modifier ses conditions de vente à tout moment. Dans ce cas, les
          conditions applicables seront celles en vigueur à la date de la commande par l’acheteur·euse.
        </p>

        <h3>Produits</h3>
        <p>
          Les produits offerts sont ceux qui figurent dans le catalogue publié sur ce site. Ces produits sont proposés
          dans la limite des stocks disponibles.
        </p>
        <p>
          Les photographies du catalogue sont les plus fidèles possibles mais ne peuvent assurer une similitude parfaite
          avec le produit proposé, notamment en ce qui concerne les couleurs ainsi que les textures du miel (liquide,
          cristallisé, etc). Les accessoires présents sur les photos ne sont pas vendus avec le produit.
        </p>
        <p>
          Face à un dysfonctionnement exceptionnel dans la gestion des stocks ou de la mauvaise récolte due à des
          imprévus climatiques et dans l’éventualité d’une indisponibilité des produits après la passation de commande
          de l’acheteur·euse, l’attrape miel avertira le client par courriel de la livraison d’une commande partielle ou
          de l’annulation de sa commande. Dans le cas où le compte bancaire de l’acheteur·euse aurait été débité,
          l’attrape miel engagera une procédure de remboursement immédiat. Le compte de l’acheteur·euse sera crédité de
          nouveau. Le délai dépendra du type de carte bancaire possédé : 3 à 8 jours pour un débit immédiat et fin de
          mois pour un débit différé.
        </p>

        <h3>Prix</h3>
        <p>
          L’attrape miel étant soumis au régime d’imposition de la TVA Remboursement forfaitaire agricole, les prix
          figurant dans le catalogue et sur chaque description des produits ou des services ne comportent pas de TVA.
        </p>
        <p>
          L’attrape miel se réserve le droit de modifier ses prix à tout moment, étant toutefois entendu que les
          produits commandés sont factures au prix en vigueur lors de l’enregistrement de la commande.
        </p>
        <p>
          Les prix indiqués comprennent les frais de traitement de commandes, de transport et de livraison selon les
          conditions prévues ci-après.
        </p>
        <p>
          La livraison s’effectue à la date prévue dans le point de retrait sélectionné par l’acheteur·euse au passage
          de la commande.
        </p>

        <h3>Commandes</h3>
        <p>
          L’acheteur·euse, qui souhaite acquérir un produit ou un service, doit obligatoirement : remplir la fiche
          d’identification sur laquelle il ou elle indiquera toutes les coordonnées demandées ; remplir le bon de
          commande en ligne en donnant toutes les références des produits ou services choisis ; valider sa commande
          après l’avoir vérifiée ; effectuer le paiement dans les conditions prévues.
        </p>
        <p>
          La confirmation de la commande entraîne acceptation des présentes conditions de vente, la reconnaissance d’en
          avoir parfaite connaissance et la renonciation à se prévaloir de ses propres conditions d’achat ou d’autres
          conditions.
        </p>
        <p>
          L’ensemble des données fournies et la confirmation enregistrée vaudront preuve de la transaction. La
          confirmation vaudra signature et acceptation des opérations effectuées.
        </p>
        <p>Le présent site communiquera par courrier électronique confirmation de la commande enregistrée.</p>
        <h3>Rétractation</h3>
        <p>
          Les acheteur·euses, personnes physiques non professionnelles, bénéficient d’un délai de rétractation de sept
          jours à compter du retrait de leur commande pour faire retour du produit à l’attrape miel pour échange ou
          remboursement sans pénalité, à l’exception des frais de retour.
        </p>
        <p>Tout produit ouvert ou consommé, même partiellement, ne sera pas ni repris ni échangé.</p>

        <h3>Modalités de paiement</h3>
        <p>Le montant total est exigible à la commande.</p>
        <p>
          Les paiements seront effectués par carte bancaire ; ils seront réalisés par le biais du système sécurisé
          Stripe qui utilise le protocole SSL de telle sorte que les informations transmises sont cryptées par un
          logiciel et qu’aucun tiers ne peut en prendre connaissance au cours du transport sur le réseau.
        </p>
        <p>
          À la demande de l’acheteur·euse, il lui sera adressé une facture sur papier portant la mention suivante :{" "}
          <i>« facture HT base TVA non applicable – article 293b du CGI »</i>.
        </p>
        <h3>Archivage - Preuve</h3>
        <p>
          L’attrape miel archivera les bons de commandes et les factures sur un support fiable et durable constituant
          une copie fidèle conformément aux dispositions de l’article 1348 du Code civil. Les registres informatisés de
          l’attrape miel seront considérés par les parties comme preuve des communications, commandes, paiements et
          transactions intervenus entre les parties.
        </p>

        <h3>Livraison</h3>
        <p>Les livraisons sont faites à la date et à l’adresse du point de retrait indiquée dans le bon de commande.</p>
        <p>La date et l’heure de livraison n’est donnée qu’à titre indicatif.</p>

        <h3>Propriété intellectuelle</h3>
        <p>
          Tous les éléments du site de L’attrape miel sont et restent la propriété intellectuelle et exclusive de
          L’attrape miel. Personne n’est autorisé à reproduire, exploiter, rediffuser, ou utiliser à quelque titre que
          ce soit, même partiellement, des éléments du site qu’ils soient logiciels, visuels ou sonores.
        </p>

        <h3>Règlement des litiges</h3>
        <p>
          Les présentes conditions de vente en ligne sont soumises à la loi française. En cas de litige, compétence est
          attribuée aux tribunaux compétents, nonobstant pluralité de défendeurs ou appel en garantie.
        </p>
      </Column>
    </Layout>
  )
}

export default LegalPage
